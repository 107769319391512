import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Your One-Stop Shop For:',
    //paragraph: 'asfasfasfasdf'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">

        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Diversifying Income
                  </div>
                <h3 className="mt-0 mb-12">
                  Token Pools
                  </h3>
                <p className="m-0">              
                    Artists can pool their tokens and take an even cut of the aggregate income!
                    <br></br><br></br>
                    Even out the good weeks and bad weeks.
                    <br></br><br></br>
                    Teams that work together can earn together!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  //src={require('./../../assets/images/features-split-image-01.png')}
                  src={require('./../../assets/images/collab.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Custom Risk Exposure
                  </div>
                <h3 className="mt-0 mb-12">
                  Music ETF Tokens
                  </h3>
                <p className="m-0">
                  Create a token to build your custom music etf: a collation of Music NFTs. Add capital to buy NFTs into the token, or as an ERC721/ERC998 tokenholder, request that you be purchased.
                  <br></br><br></br>
                  On creation, all the shares are issued to the token creator. These shares can be sold off.
                  <br></br><br></br>
                  Labels can finally invest in Music without having to go through the record + distibution process.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  //src={require('./../../assets/images/features-split-image-02.png')}
                  src={require('./../../assets/images/ETF.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Remove Counterparty Risk
                  </div>
                <h3 className="mt-0 mb-12">
                  NFT Licensing
                  </h3>
                <p className="m-0">
                  Create a standard license and earn income by selling the rights to use your work.
                  <br></br><br></br>
                  Propose a lease agreement to someone for their work.
                  <br></br><br></br>
                  Tie several income sources to 1 token to show your work's value, and use that data to auction it!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  //src={require('./../../assets/images/features-split-image-03.png')}
                  src={require('./../../assets/images/licensing.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Everyone Gets the Major Label Treatment
                  </div>
                <h3 className="mt-0 mb-12">
                  Decentralized Royalty Exchange
                  </h3>
                <p className="m-0">
                  Buy and Sell the right to receive specific kinds of income that is sent to your token.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  //src={require('./../../assets/images/features-split-image-02.png')}
                  src={require('./../../assets/images/DEX.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Remove Counterparty Risk
                  </div>
                <h3 className="mt-0 mb-12">
                  Decentralized License Marketplace
                  </h3>
                <p className="m-0">
                  Buy and Sell standardized contracts to lease instrumentals, vocals, etc.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  //src={require('./../../assets/images/features-split-image-03.png')}
                  src={require('./../../assets/images/marketplace.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>



            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Copyright Protection and Empowering the Artist
                  </div>
                <h3 className="mt-0 mb-12">
                  Create an NFT: Tokenization
                  </h3>
                <p className="m-0">
                  Upload your work and we create an NFT that represents your work. <br></br><br></br>
                  NoteBlock offers Auctionable NFTs that you can put for sale whenever you wish, and NoteBlock offers Composable NFTs to integrate all the components that make up a final record.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  //src={require('./../../assets/images/features-split-image-02.png')}
                  src={require('./../../assets/images/NFT.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>



          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;